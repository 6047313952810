/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, memo } from 'react';

const useStyles = makeStyles({
  uploadFile: {
    marginTop: 24,

    '& label': {
      marginBottom: 0,
    },
  },
  uploadFileLabel: {
    fontWeight: 700,
    lineHeight: '19px',
    color: '#383838',
    marginBottom: '10px !important',
  },
  inputLabel: {
    width: 350,
    padding: '14px 15px',
    backgroundColor: '#E0ECF5',
    color: '#383838',
    pointerEvents: 'none',
  },
  uploadFileInput: {
    height: 50,
    border: '1px solid #A5A5A5',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  fileName: {
    flex: 1,
    padding: '14px',
    border: '1px solid #A5A5A5',
    borderTop: 0,
    borderBottom: 0,
    height: 50,
  },
  uploadButton: {
    padding: '6px 14px',
    color: '#fff',
    backgroundColor: '#359AE1',
    borderRadius: '3px',
    margin: '0 10px',
    fontSize: 15,
    height: 32,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  removeButton: {
    padding: '6px 14px',
    color: '#fff',
    backgroundColor: 'red',
    borderRadius: '3px',
    margin: '0 10px',
    fontSize: 15,
    height: 32,

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const UploadFileComponent = ({ index, item, listFile = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.uploadFile}>
      <label className={classes.uploadFileLabel}>
        {`${index}. ${item?.label}`}
        {item?.required && <label className="required" />}
      </label>
      {item?.field?.map((element, elementIndex) => (
        <Fragment key={elementIndex}>
          <div className={classes.uploadFileInput}>
            <label className={classes.inputLabel}>{element?.label}</label>
            <input id={element?.name} type="file" name={element?.name} className="media-file" hidden />
            {
              listFile[element?.name]?.name ? <a
              href={`${listFile[element?.name]?.preview || ''}`}
              className={classes.fileName}
              target="_blank"
              rel="noopener noreferrer"
            >
              {listFile[element?.name]?.name}
            </a> : <a
              className={classes.fileName}
              rel="noopener noreferrer"
            >
              {listFile[element?.name]?.name}
            </a>
            }
            
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default memo(UploadFileComponent);
