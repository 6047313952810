import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useLocation } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
import LinkAdministratorItems from "./child-components/link-adminstrator-items.view";

//--- Material Icon
import DescriptionIcon from "@material-ui/icons/Description";
import EmailIcon from "@material-ui/icons/Email";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import PostAddIcon from "@material-ui/icons/PostAdd";
import MapIcon from "@material-ui/icons/Map";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import HistoryIcon from "@material-ui/icons/History";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import TableChartIcon from "@material-ui/icons/TableChart";
import { makeStyles } from "@material-ui/core";
//--- Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import * as clientSettingAction from "../../redux/store/client_setting/client_setting.store";

//--- Style
import "./sidebar.scss";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#00923F",
  },
}));

function Sidebar(props) {
  const { settings, getSettings } = props;

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    setClientSetting(settings);
  }, [settings]);

  const [clientSetting, setClientSetting] = useState();

  const [isToggleSidebar, setIsToggleSidebar] = useState(false);
  const currentLocation = useLocation();
  const onToggleSidebar = () => {
    setIsToggleSidebar(!isToggleSidebar);
  };
  const currentIsHomePage = currentLocation.pathname === "/";
  const currentIsPlanningAnnouncementProcessPage =
    currentLocation.pathname === UrlCollection.PlanningAnnouncementProcess;
  const currentIsSliderPage = currentLocation.pathname === UrlCollection.Slider;
  const currentIsMapDataPage =
    currentLocation.pathname === UrlCollection.MapData;
  const currentIsNewsPage = currentLocation.pathname === UrlCollection.News;
  const currentIsNotificationPage =
    currentLocation.pathname === UrlCollection.Notification;
  const currentIsDocumentManagementPage =
    currentLocation.pathname === UrlCollection.DocumentManagement;
  const currentIsProjectManagementPage =
    currentLocation.pathname === UrlCollection.ProjectManagement;
  const currentIsCategoryManagementPage =
    currentLocation.pathname === UrlCollection.PlanningCategory;
const currentIsMemberManagementPage =
    currentLocation.pathname === UrlCollection.MemberManagement;
	
  const currentIsAdministratorPages =
    !currentIsHomePage &&
    !currentIsPlanningAnnouncementProcessPage &&
    !currentIsSliderPage &&
    !currentIsMapDataPage &&
    !currentIsNewsPage &&
    !currentIsNotificationPage &&
    !currentIsDocumentManagementPage &&
    !currentIsProjectManagementPage &&
	!currentIsMemberManagementPage &&
    !currentIsCategoryManagementPage;

  return (
    <span>
      {clientSetting && (
        <ul
          style={{ background: clientSetting.color }}
          className={
            "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" +
            (isToggleSidebar ? " toggled" : "")
          }
          id="accordionSidebar"
        >
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="/"
          >
            <div className="sidebar-brand-icon">
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="Logo"
                width="50"
              />
            </div>
            <div className="sidebar-brand-text ml-2">
              CSDL QH
              <br />
              QUỐC GIA
            </div>
          </a>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item">
            <Link
              className={`nav-link ${currentIsHomePage && "is-active"}`}
              to="/"
            >
              <HomeIcon fontSize="small" className="mr-2" />
              <span>Trang chủ</span>
            </Link>
          </li>

          <hr className="sidebar-divider mb-0" />

          <li className="nav-item">
            <Link
              className={`nav-link ${
                currentIsPlanningAnnouncementProcessPage && "is-active"
              }`}
              to={UrlCollection.PlanningAnnouncementProcess}
            >
              <AssignmentIcon fontSize="small" className="mr-2" />
              <span>Quản lý quy hoạch</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${
                currentIsAdministratorPages && "is-active"
              }`}
              to=""
              data-toggle="collapse"
              data-target="#collapseTwo1"
              aria-expanded="true"
              aria-controls="collapseTwo1"
            >
              <AssignmentIcon fontSize="small" className="mr-2" />
              <span>Quản lý nộp/phê duyệt QH</span>

              <FontAwesomeIcon
                icon={faChevronRight}
                className="float-right mt-1"
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                className="float-right mt-1"
              />
            </Link>

            <div
              id="collapseTwo1"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <LinkAdministratorItems
                  icon={DescriptionIcon}
                  pageLink={UrlCollection.PlanningProvincial}
                  title="Quy hoạch Tỉnh"
                />
                <LinkAdministratorItems
                  icon={EmailIcon}
                  pageLink={UrlCollection.PlanningRegion}
                  title="Quy hoạch Vùng/Cấp Quốc Gia"
                />
              </div>
            </div>
          </li>

          <li className="nav-item">
            <Link
              className={`nav-link ${
                currentIsCategoryManagementPage && "is-active"
              }`}
              to={UrlCollection.PlanningCategory}
            >
              <AssignmentIcon fontSize="small" className="mr-2" />
              <span>Quản lý loại quy hoạch</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${currentIsNewsPage && "is-active"}`}
              to={UrlCollection.News}
            >
              <PostAddIcon fontSize="small" className="mr-2" />
              <span>Tin tức</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${currentIsNewsPage && "is-active"}`}
              to={UrlCollection.Videos}
            >
              <PlayCircleOutline fontSize="small" className="mr-2" />
              <span>Video</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${currentIsNotificationPage && "is-active"}`}
              to={UrlCollection.Notification}
            >
              <PostAddIcon fontSize="small" className="mr-2" />
              <span>Thông báo</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                currentIsProjectManagementPage && "is-active"
              }`}
              to={UrlCollection.ProjectManagement}
            >
              <PostAddIcon fontSize="small" className="mr-2" />
              <span>Dự án</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                currentIsDocumentManagementPage && "is-active"
              }`}
              to={UrlCollection.DocumentManagement}
            >
              <PostAddIcon fontSize="small" className="mr-2" />
              <span>Hồ sơn, văn bản</span>
            </Link>
          </li>
		  <li className="nav-item">
            <Link
              className={`nav-link ${
                currentIsMemberManagementPage && "is-active"
              }`}
              to={UrlCollection.MemberManagement}
            >
              <AccountBoxIcon fontSize="small" className="mr-2" />
              <span>Quản lý thành viên</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link collapsed ${
                currentIsAdministratorPages && "is-active"
              }`}
              to=""
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <SettingsIcon fontSize="small" className="mr-2" />
              <span>Administrator</span>

              <FontAwesomeIcon
                icon={faChevronRight}
                className="float-right mt-1"
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                className="float-right mt-1"
              />
            </Link>

            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <LinkAdministratorItems
                  icon={DescriptionIcon}
                  pageLink={UrlCollection.Introduce}
                  title="Giới thiệu"
                />
                <LinkAdministratorItems
                  icon={EmailIcon}
                  pageLink={UrlCollection.EmailTemplate}
                  title="Email Template"
                />

                <LinkAdministratorItems
                  icon={AllInboxIcon}
                  pageLink={UrlCollection.EmailGenerated}
                  title="Khởi tạo email"
                />

                <LinkAdministratorItems
                  icon={ContactPhoneIcon}
                  pageLink={UrlCollection.ContactManagement}
                  title="Liên hệ"
                />

                <LinkAdministratorItems
                  icon={PeopleIcon}
                  pageLink={UrlCollection.RoleManagement}
                  title="Chức vụ"
                />

                <LinkAdministratorItems
                  icon={AccountBoxIcon}
                  pageLink={UrlCollection.UserManagement}
                  title="Quản lý người dùng"
                />
                <LinkAdministratorItems
                  icon={() => (
                    <img
                      className="mr-2 mt-n1"
                      src={require("../../assets/icon/commune.png")}
                      alt="commune"
                    />
                  )}
                  pageLink={UrlCollection.CommuneManagement}
                  title="Quản lý xã/phường"
                />

                <LinkAdministratorItems
                  icon={() => (
                    <img
                      className="mr-2 mt-n1"
                      src={require("../../assets/icon/district.png")}
                      alt="district"
                    />
                  )}
                  pageLink={UrlCollection.DistrictManagement}
                  title="Quản lý quận/huyện"
                />

                <LinkAdministratorItems
                  icon={() => (
                    <img
                      className="mr-2 mt-n1"
                      src={require("../../assets/icon/province.png")}
                      alt="province"
                    />
                  )}
                  pageLink={UrlCollection.ProvinceManagement}
                  title="Quản lý tỉnh/thành phố"
                />

                <LinkAdministratorItems
                  icon={() => (
                    <img
                      className="mr-2 mt-n1"
                      src={require("../../assets/icon/group-links.png")}
                      alt="group-links"
                    />
                  )}
                  pageLink={UrlCollection.LinkGroup}
                  title="Danh sách nhóm"
                />

                <LinkAdministratorItems
                  icon={() => (
                    <img
                      className="mr-2 mt-n1"
                      src={require("../../assets/icon/link.png")}
                      alt="link"
                    />
                  )}
                  pageLink={UrlCollection.ServiceLink}
                  title="Danh sách liên kết"
                />

                <LinkAdministratorItems
                  icon={HistoryIcon}
                  pageLink={UrlCollection.UserLogHistory}
                  title="Nhật ký người dùng"
                />

                <LinkAdministratorItems
                  icon={ViewComfyIcon}
                  pageLink={UrlCollection.LandType}
                  title="Quản lý loại đất"
                />

                <LinkAdministratorItems
                  icon={ViewAgendaIcon}
                  pageLink={UrlCollection.LandTypeDetail}
                  title="Quản lý chi tiết loại đất"
                />

                <LinkAdministratorItems
                  icon={CallToActionIcon}
                  pageLink={UrlCollection.OpinionForm}
                  title="Tạo form ý kiến"
                />

                <LinkAdministratorItems
                  icon={TableChartIcon}
                  pageLink={UrlCollection.TableStructure}
                  title="Sửa cấu trúc bảng"
                />
              </div>
            </div>
          </li>

          <hr className="sidebar-divider d-none d-md-block" />

          <div className="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={onToggleSidebar}
            >
              <ChevronLeftIcon
                fontSize="small"
                className={"text-white" + (isToggleSidebar ? " d-none" : "")}
              />
              <ChevronRightIcon
                fontSize="small"
                className={"text-white" + (!isToggleSidebar ? " d-none" : "")}
              />
            </button>
          </div>
        </ul>
      )}
    </span>
  );
}

const mapStateToProps = (state) => ({
  settings: state.clientSetting.clientSetting,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSettings: clientSettingAction.getSettings,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
