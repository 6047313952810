export const ApiUrl = {
  //--- Account
  Login: "api/Account/Login",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPassword: "​api​/Account​/ResetPassword",

  //--- Email Generated
  GetListEmailGenerated: "api/admin/EmailGenerated/GetListEmailGenerated",
  GetDetailEmailGenerated: "api/admin/EmailGenerated/GetDetailEmailGenerated",

  //--- Email Template
  GetListEmailTemplates: "api/admin/EmailTemplate/GetListEmailTemplates",
  GetDetailEmailTemplate: "api/admin/EmailTemplate/GetDetailEmailTemplate",
  CreateEmailTemplate: "api/admin/EmailTemplate/CreateEmailTemplate",
  UpdateEmailTemplate: "api/admin/EmailTemplate/UpdateEmailTemplate",
  DeleteEmailTemplate: "api/admin/EmailTemplate/DeleteEmailTemplate",

  //--- Contact Management
  GetListContactManagement: "api/admin/EmailGenerated/GetListContactToAdmin",
  GetContactDetail: "api/admin/EmailGenerated/GetDetailContactToAdmin",
  ReplyUserContactByEmail: "api/admin/EmailGenerated/ReplyUserContactByEmail",
  GetDetailEmailTemplate: "api/admin/EmailTemplate/GetDetailEmailTemplate",

  //--- Role Management
  GetListRoleManagement: "api/admin/Role/GetListRole",
  GetDetailRoleManagement: "api/admin/Role/GetDetailRole",
  CreateRoleManagement: "api/admin/Role/CreateRole",
  UpdateRoleManagement: "api/admin/Role/UpdateRole",
  DeleteRoleManagement: "api/admin/Role/DeleteRole",

  //--- Map
  CreateMap: "api/admin/Map/create-map",
  GetMapById: "api/admin/Map/get-map-by-id",
  GetAllBaseMapSetting: "api/admin/BaseMapSetting/get-all",
  GetPgSchema: "api/admin/PgTable/get-pg-schema",
  GetPageByTable: "api/admin/PgTable/get-pg-table",
  UpdateMap: "api/admin/Map/update-map",
  GetPgTable: "api/admin/PgTable/get-pg-table",
  DeleteMap: "api/admin/Map/delete-map",

  //--- Planning
  GetListPlanning: "api/admin/Planning/GetListPlanning",
  GetLookUpPlanning: "api/admin/Planning/GetLookUpPlanning",
  GetLookUpPlanningStatus: "api/admin/Planning/GetLookUpPlanningStatus",
  GetLookUpPlanningLevel: "api/admin/Planning/GetLookUpPlanningLevel",
  GetLookUpPlanningType: "api/admin/Planning/GetLookUpPlanningType",
  GetLookUpDocumentType: "api/admin/Planning/GetLookUpDocumentType",
  GetDetailPlaning: "api/admin/Planning/GetDetailPlaning",
  CreatePlanning: "api/admin/Planning/CreatePlanning",
  UpdatePlanning: "api/admin/Planning/UpdatePlanning",
  DeletePlanning: "api/admin/Planning/DeletePlanning",
  PlanningRelationshipType:
    "api/admin/Planning/get-all-planning-relationship-type",
  PlanningRelationshipTypeById:
    "api/admin/Planning/get-all-planning-relationship-by-planning-id",
  PlanningApprovedById:
    "api/admin/Planning/get-look-up-approved-planning-by-id",
  PutPlanningRelationship:
    "api/admin/Planning/update-multiple-planning-relationship",
  GetAllBoundaries: "api/admin/Planning/get-all-boundaries",
  CheckExistedPlanning: "api/admin/Planning/CheckExistedPlanning",
  GetLookupLayerByPlanningId:
    "api/admin/Planning/get-lookup-layer-by-planning-id",

  //--- Consultant Community
  GetListConsultCommunity:
    "api/admin/ConsultantCommunity/GetListConsultCommunity",
  GetDetailConsultCommunity:
    "api/admin/ConsultantCommunity/GetDetailConsultCommunity",
  CreateConsultCommunity:
    "api/admin/ConsultantCommunity/CreateConsultCommunity",
  UpdateConsultCommunity:
    "api/admin/ConsultantCommunity/UpdateConsultCommunity",
  DeleteConsultCommunity:
    "api/admin/ConsultantCommunity/DeleteConsultCommunity",
  GetConsultCommunityStatusLookup:
    "api/admin/ConsultantCommunity/GetConsultCommunityStatusLookup",
  GetConsultCommunityByPlanning:
    "api/admin/ConsultantCommunity/GetConsultCommunityByPlanning",
  ExportConsultantList: "api/ExportData/ExportConsultantList",

  //--- Statement
  GetListStatement: "api/admin/Statement/GetListStatement",
  // GetDetailStatement: "​api​/admin​/Statement​/GetStatementByPlanning",
  CreateStatement: "api/admin/Statement/CreateStatement",
  UpdateStatement: "api/admin/Statement/UpdateStatement",
  DeleteStatement: "api/admin/Statement/DeleteStatement",
  GetStatementStatusLookup: "api/admin/Statement/GetStatementStatusLookup",
  GetListServiceLink: "api/admin/Statement/GetListServiceLink",
  SearchStatement: "api/admin/Statement/search-statement-by",
  GetStatementReport: "api/admin/Statement/GetStatementReport",

  // Home page
  GetListHomePage: "api/admin/HomePage/GetListHomePage",
  GetDetailHomePage: "api/admin/HomePage/GetDetailHomePage",
  CreateHomePage: "api/admin/HomePage/CreateHomePage",
  UpdateHomePage: "api/admin/HomePage/UpdateHomePage",
  DeleteHomePage: "api/admin/HomePage/DeleteHomePage",
  DeleteAvatar: "api/admin/HomePage/DeleteAvatar",

  // User Management
  GetListUserManagement: "api/admin/User/GetUserManagement",
  GetDetailUserManagement: "api/admin/User/GetUserDetail",
  CreateUserManagement: "api/admin/User/CreateUser",
  UpdateUserManagement: "api/admin/User/UpdateUser",
  DeleteUserManagement: "api/admin/HomePage/DeleteHomePage",
  ActiveUserManagement: "api/admin/User/ActiveUser",
  ResetPasswordUserManagement: "api/admin/User/ResetPassword",
  GetRoleLookupUserManagement: "api/admin/Role/GetRoleLookup",
  ActiveUser: "api/admin/User/ActiveUser",
  DeActiveUser: "api/admin/User/DeActiveUser",

  // Member Management
  GetListMemberManagement: "api/admin/Member/GetMemberManagement",
  GetDetailMemberManagement: "api/admin/Member/GetMemberDetail",
  CreateMemberManagement: "api/admin/Member/CreateMember",
  UpdateMemberManagement: "api/admin/Member/UpdateMember",
  DeleteMemberManagement: "api/admin/HomePage/DeleteHomePage",
  ActiveMemberManagement: "api/admin/Member/ActiveMember",
  ResetPasswordMemberManagement: "api/admin/Member/ResetPassword",
  GetRoleLookupMemberManagement: "api/admin/Role/GetRoleLookup",
  ActiveMember: "api/admin/Member/ActiveMember",
  DeActiveMember: "api/admin/Member/DeActiveMember",

  //ApprovalAgencyLevel
  ApprovalAgencyLevel:
    "api/admin/ApprovalAgencyLevel/get-approval-agency-level",
  GetLookupDistrict: "api/admin/Administrative/GetLookupDistrict",

  //Document
  GetAllDocumentByPlanning: "api/admin/Document/GetAllDocumentByParentId",
  CreateFolder: "api/admin/Document/CreateDocumentFolder",
  ReNameDocument: "api/admin/Document/RenameDocument",
  CreateFile: "api/admin/Document/UploadDocument",
  RemoveDocument: "api/admin/Document/DeleteDocument",
  MoveDocument: "api/admin/Document/MoveDocument",
  SearchDocument: "api/admin/Document/SearchDocument",
  SetDocumentStatus: "api/admin/Document/SetDocumentStatus",
  DownloadDocument: "api/admin/Document/DownloadDocument",
  GetAllFolder: "api/admin/Document/ListAllFolder",
  GetDocumentByFolder: "api/admin/Document/GetDocumentByFolder",

  // CommuneManagement
  GetListCommune: "api/admin/Administrative/GetListCommune",
  GetDetailCommune: "api/admin/Administrative/GetDetailCommune",
  GetDetailCommune: "api/admin/Administrative/GetDetailCommune",
  CreateCommune: "api/admin/Administrative/CreateCommune",
  UpdateCommune: "api/admin/Administrative/UpdateCommune",
  DeleteCommune: "api/admin/Administrative/DeleteCommune",
  GetDistrictByProvinceId: "api/admin/Administrative/GetLookupDistrict",
  GetLookupCommune: "api/admin/Administrative/GetLookupCommune",

  // DistrictManagement
  GetListDistrict: "api/admin/Administrative/GetListDistrict",
  GetDetailDistrict: "api/admin/Administrative/GetDetailDistrict",
  GetLookupDistrict: "api/admin/Administrative/GetLookupDistrict",
  CreateDistrict: "api/admin/Administrative/CreateDistrict",
  UpdateDistrict: "api/admin/Administrative/UpdateDistrict",
  DeleteDistrict: "api/admin/Administrative/DeleteDistrict",

  // ProvinceManagement
  GetListProvince: "api/admin/Administrative/GetListProvince",
  GetDetailProvince: "api/admin/Administrative/GetDetailProvince",
  CreateProvince: "api/admin/Administrative/CreateProvince",
  UpdateProvince: "api/admin/Administrative/UpdateProvince",
  DeleteProvince: "api/admin/Administrative/DeleteProvince",
  GetLookupProvince: "api/admin/Administrative/GetLookupProvince",

  //Link Group Management
  GetListLinkGroup: "api/admin/Administrative/GetListLinkGroup",
  GetDetailLinkGroup: "api/admin/Administrative/GetDetailLinkGroup",
  CreateLinkGroup: "api/admin/Administrative/CreateLinkGroup",
  UpdateLinkGroup: "api/admin/Administrative/UpdateLinkGroup",
  DeleteLinkGroup: "api/admin/Administrative/DeleteLinkGroup",

  //Service Link Management
  GetListServiceLink: "api/admin/Administrative/GetListLinkService",
  GetDetailServiceLink: "api/admin/Administrative/GetDetailLinkService",
  CreateServiceLink: "api/admin/Administrative/CreateLinkService",
  UpdateServiceLink: "api/admin/Administrative/UpdateLinkService",
  DeleteServiceLink: "api/admin/Administrative/DeleteLinkService",
  GetLookupLinkGroup: "api/admin/Administrative/GetLookupLinkGroup",

  //Userlog
  GetAllLogHistory: "api/admin/LogHistory/GetAllLogHistory",

  //Land Type
  GetListLandType: "api/admin/Land/GetListLandType",
  LookupLandType: "api/admin/Land/LookupLandType",
  GetLandTypeById: "api/admin/Land/GetLandTypeById",
  CreateLandType: "api/admin/Land/CreateLandType",
  UpdateLandType: "api/admin/Land/UpdateLandType",
  DeleteLandType: "api/admin/Land/DeleteLandType",

  //Land Type Detail
  GetListLandTypeDetail: "api/admin/Land/GetListLandTypeDetail",
  GetLandTypeDetailById: "api/admin/Land/GetLandTypeDetailById",
  CreateLandTypeDetail: "api/admin/Land/CreateLandTypeDetail",
  UpdateLandTypeDetail: "api/admin/Land/UpdateLandTypeDetail",
  DeleteLandTypeDetail: "api/admin/Land/DeleteLandTypeDetail",

  //Opinion Form
  CreateFormTemplate: "api/admin/ConsultantCommunity/CreateFormTemplate",
  UpdateFormTemplate: "api/admin/ConsultantCommunity/UpdateFormTemplate",
  GetListFormTemplate: "api/admin/ConsultantCommunity/GetListFormTemplate",
  GetDetailFormTemplate: "api/admin/ConsultantCommunity/GetDetailFormTemplate",
  DeleteFormTemplate: "api/admin/ConsultantCommunity/DeleteFormTemplate",
  GetLookupFormExample: "api/admin/ConsultantCommunity/GetLookupFormExample",

  GetDetailFeedback: "api/admin/ConsultantCommunityAdmin/GetDetailFeedback",
  SaveReply: "api/admin/ConsultantCommunityAdmin/SaveReply",

  //utils-shape-file
  Shape_File_Step_1: "api/admin/Utils/step-1-import-and-unzip-file",
  Shape_File_Step_2: "api/admin/Utils/step-2-import-shape-file-to-postgis",
  Shape_File_Step_3: "api/admin/Utils/step-3-created-sld-and-import-to-postgis",
  Shape_File_Step_4: "api/admin/Utils/step-4-publish",
  // Shape_File_Publish_Finish: "api/admin/Utils/publish-again",

  //utils-tiff-file
  Import_Tiff_File: "api/admin/Utils/import-tif",

  //News
  News_Create: "api/admin/News/Create",
  News_Update: "api/admin/News/Update",
  News_Delete: "api/admin/News/Delete",
  News_Restore: "api/admin/News/Restore/{id}",
  News_EmptyTrash: "api/admin/News/EmptyTrash",
  News_ChangeActiveStatus: "api/admin/News/ChangeActiveStatus",
  News_ChangeHotStatus: "api/admin/News/ChangeHotStatus",
  News_ChangeFeatureStatus: "api/admin/News/ChangeFeatureStatus",
  News_GetListAll: "api/admin/News/GetListAll",
  News_GetListByCat: "api/admin/News/GetListByCat",
  News_GetListHot: "api/admin/News/GetListHot",
  News_GetListByStatus: "api/admin/News/GetListByStatus",
  News_GetDetail: "api/admin/News/GetDetail/{id}",

  //Videos
  Videos_Create: "api/admin/Video/Create",
  Videos_Update: "api/admin/Video/Update",
  Videos_Delete: "api/admin/Video/Delete",
  Videos_Restore: "api/admin/Video/Restore/{id}",
  Videos_EmptyTrash: "api/admin/Video/EmptyTrash",
  Videos_ChangeActiveStatus: "api/admin/Video/ChangeActiveStatus",
  Videos_ChangeHotStatus: "api/admin/Video/ChangeHotStatus",
  Videos_ChangeFeatureStatus: "api/admin/Video/ChangeFeatureStatus",
  Videos_GetListAll: "api/admin/Video/GetListAll",
  Videos_GetListByCat: "api/admin/Video/GetListByCat",
  Videos_GetListHot: "api/admin/Video/GetListHot",
  Videos_GetListByStatus: "api/admin/Video/GetListByStatus",
  Videos_GetDetail: "api/admin/Video/GetDetail/{id}",

  DownloadFile: "api/cms/Planning/DownloadFileBinary",

  //--- Document Repository
  DocumentRepositoryCreateFolder:
    "api/admin/DocumentUpload/CreateDocumentFolder",
  DocumentRepositoryUploadDocument: "api/admin/DocumentUpload/UploadDocument",
  DocumentRepositoryDownloadDocument:
    "api/admin/DocumentUpload/DownloadDocument",
  DocumentRepositoryDeleteDocument: "api/admin/DocumentUpload/DeleteDocument",
  DocumentRepositoryGetAllDocumentByParentId:
    "api/admin/DocumentUpload/GetAllDocumentByParentId",
  DocumentRepositoryMoveDocument: "api/admin/DocumentUpload/MoveDocument",
  DocumentRepositorySearchDocument: "api/admin/DocumentUpload/SearchDocument",
  DocumentRepositoryRenameDocument: "api/admin/DocumentUpload/RenameDocument",
  DocumentRepositoryGetParentDocumentById:
    "api/admin/DocumentUpload/GetParentDocumentById",
  UrlUploadFromEditor: "api/admin/DocumentUpload/UploadImage",
  // Security Matrix
  GetSecurityMatrixDetail: "api/admin/Permission/GetSecurityMatrixDetail",
  GetActionLookup: "api/admin/Permission/GetActionLookup",
  GetScreenLookup: "api/admin/Permission/GetScreenLookup",
  UpdateSecurityMatrix: "api/admin/Permission/UpdateSecurityMatrix",

  GetDefaultCordinate: "api/cms/Administrative/GetDefaultCordinate",
  UpdateDefaultCordinate: "api/admin/Administrative/UpdateDefaultCordinate",
  GetClientSetting: "api/cms/Administrative/GetClientSetting",

  //Introduce
  Introduce_Create: "api/admin/Introduce/Create",
  Introduce_Update: "api/admin/Introduce/Update",
  Introduce_GetListAll: "api/admin/Introduce/GetListAll",
  Introduce_GetDetail: "api/admin/Introduce/GetDetail/{id}",

  //Notification
  Notification_Create: "api/admin/Notification/Create",
  Notification_Update: "api/admin/Notification/Update",
  Notification_Delete: "api/admin/Notification/Delete",
  Notification_Restore: "api/admin/Notification/Restore/{id}",
  Notification_EmptyTrash: "api/admin/Notification/EmptyTrash",
  Notification_ChangeActiveStatus: "api/admin/Notification/ChangeActiveStatus",
  Notification_ChangeHotStatus: "api/admin/Notification/ChangeHotStatus",
  Notification_ChangeFeatureStatus:
    "api/admin/Notification/ChangeFeatureStatus",
  Notification_GetListAll: "api/admin/Notification/GetListAll",
  Notification_GetListByCat: "api/admin/Notification/GetListByCat",
  Notification_GetListHot: "api/admin/Notification/GetListHot",
  Notification_GetListByStatus: "api/admin/Notification/GetListByStatus",
  Notification_GetDetail: "api/admin/Notification/GetDetail/{id}",

  // Planning_Category_Management
  Planning_Category_GetListAll: "api/admin/PlanningCategory/GetListAll",
  Planning_Category_GetDetail: "api/admin/PlanningCategory/GetDetail/{id}",
  Planning_Category_Create: "api/admin/PlanningCategory/Create",
  Planning_Category_Update: "api/admin/PlanningCategory/Update",
  Planning_Category_Delete: "api/admin/PlanningCategory/Delete/{id}",
  Planning_Category_GetLookup: "api/admin/PlanningCategory/GetLookup",
  Planning_Category_GetDetailWithChild:
    "/api/admin/PlanningCategory/GetDetailWithChild",

  // Government Management
  Government_GetListAll: "api/admin/Government/GetListAll",
  Government_GetDetail: "api/admin/Government/Delete/{id}",
  Government_Create: "​api​/admin​/Government​/Create",
  Government_Update: "api/admin/Government/Update",
  Government_Delete: "api/admin/Government/Delete/{id}",
  Government_GetLookup: "api/admin/Government/GetLookup",
  Government_GetDetailWithChild:
    "/api/admin/PlanningCategory/GetDetailWithChild",

  // Update Frequency Management
  Update_Frequency_GetListAll: "api/admin/UpdateFrequency/GetListAll",
  Update_Frequency_GetDetail: "api/admin/UpdateFrequency/Delete/{id}",
  Update_Frequency_Create: "​api​/admin​/UpdateFrequency​/Create",
  Update_Frequency_Update: "api/admin/UpdateFrequency/Update",
  Update_Frequency_Delete: "api/admin/UpdateFrequency/Delete/{id}",
  Update_Frequency_GetLookup: "api/admin/UpdateFrequency/GetLookup",

  // Project Status Management
  Project_Status_GetListAll: "api/admin/ProjectStatus/GetListAll",
  Project_Status_GetDetail: "api/admin/ProjectStatus/Delete/{id}",
  Project_Status_Create: "​api​/admin​/ProjectStatus​/Create",
  Project_Status_Update: "api/admin/ProjectStatus/Update",
  Project_Status_Delete: "api/admin/ProjectStatus/Delete/{id}",
  Project_Status_GetLookup: "api/admin/ProjectStatus/GetLookup",

  // Project Type Management
  Project_Type_GetListAll: "api/admin/ProjectType/GetListAll",
  Project_Type_GetDetail: "api/admin/ProjectType/Delete/{id}",
  Project_Type_Create: "​api​/admin​/ProjectType​/Create",
  Project_Type_Update: "api/admin/ProjectType/Update",
  Project_Type_Delete: "api/admin/ProjectType/Delete/{id}",
  Project_Type_GetLookup: "api/admin/ProjectType/GetLookup",

  // Project Field Management
  Project_Field_GetListAll: "api/admin/ProjectField/GetListAll",
  Project_Field_GetDetail: "api/admin/ProjectField/Delete/{id}",
  Project_Field_Create: "​api​/admin​/ProjectField/Create",
  Project_Field_Update: "api/admin/ProjectField/Update",
  Project_Field_Delete: "api/admin/ProjectField/Delete/{id}",
  Project_Field_GetLookup: "api/admin/ProjectField/GetLookup",

  //Project
  Project_Create: "api/admin/Project/Create",
  Project_Update: "api/admin/Project/Update",
  Project_Delete: "api/admin/Project/Delete/{id}",
  Project_Change_Active: "/api/admin/Project/ChangeActive/{id}",
  Project_GetListAll: "/api/admin/Project/GetListAll",
  Project_GetDetail: "/api/admin/Project/GetDetail/{id}",

  GetRecordsAll: "/api/admin/Records/GetListAll",
  UpdateRecords: "/api/admin/Records/UpdateStatus",
  GetLookupRecordsType: "/api/admin/Records/GetLookupRecordsType",
  GetListRecordsTypeByPlanningKind:
  "/api/admin/Records/GetListRecordsTypeByPlanningKind",
  GetRecordsDetail: "/api/admin/Records/GetDetail",
  GetListRecordsFile: "/api/admin/Records/GetListRecordsFile",
  GetListRecordsType: "/api/admin/Records/GetListRecordsType",

  GetLookupPlanningKind: "/api/admin/PlanningCategory/GetLookupPlanningKind",
  GetLookupProvinceIds: "/api/admin/Administrative/GetLookupProvince",
};
